<template>
  <router-view></router-view>
  <div id="elevator-doors" :class="{ 'open-elevator' : activated && doorOpen }">
    <div id="door-1"></div>
    <div id="door-2"></div>
  </div>
  <div id="instruction" :class="{'instruction-hide' : activated }">
    <p>select volume</p>
  </div>
  <div id="volume-interface" :class="{ 'volume-transition' : sound && !foldOutVolume }">
    <div id="sound-tab" @click="toggleVolume()">
      <svg viewBox="0 0 20 20" width="22" height="22">
        <path class="cls-1" d="M15,8a5,5,0,0,1,1,7,4.74,4.74,0,0,1-1,1" fill="none" stroke="var(--light-volume-color)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" transform="translate(-2 -3.07)"/>
        <path class="cls-1" d="M17.7,5A9,9,0,0,1,19,17.66,9.43,9.43,0,0,1,17.7,19" fill="none" stroke="var(--light-volume-color)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" transform="translate(-2 -3.07)"/>
        <path class="cls-1" d="M6,15H4a1,1,0,0,1-1-1V10A1,1,0,0,1,4,9H6L9.5,4.5A.8.8,0,0,1,11,5V19a.8.8,0,0,1-1.5.5L6,15" fill="var(--light-volume-color)" transform="translate(-2 -3.07)"/>
      </svg>
    </div>
    <div id="volume-flex-content">
      <div id="volume-plate">
        <p>
          <span v-if="hover == 0">0%</span>
          <span v-if="hover == 1">25%</span>
          <span v-if="hover == 2">50%</span>
          <span v-if="hover == 3">75%</span>
          <span v-if="hover == 4">100%</span>
        </p>
      </div>

      <div class="button volume" :class="{ v : hover == 0, w : hover == 1, x : hover == 2, y : hover == 3, z : hover == 4 }">
        <div 
          v-on:mouseover="hover = 0"
          v-on:click="setVolume(0)">
        </div>
        <div
        v-on:mouseover="hover = 1"
          v-on:click="setVolume(0.25)">
        </div>
        <div
        v-on:mouseover="hover = 2"
          v-on:click="setVolume(0.50)">
        </div>
        <div
        v-on:mouseover="hover = 3"
          v-on:click="setVolume(0.75)">
        </div>
        <div
        v-on:mouseover="hover = 4"
          v-on:click="setVolume(1)">
        </div>
      </div>
    </div>
  </div>
<!-- <VolumeButton v-else/> -->
</template>

<script>
// possible changeVolume button if necessary
// import VolumeButton from "./components/VolumeButton.vue"

import axios from "axios";

export default {
  name: "App",
  components: {
    // VolumeButton
  },
  data() {
    return {
      sound: false,
      hover: 0,
      stylingData: null,
      token: "522ed707c8c8490f117c3d037998f601"
    };
  },
  created() {
    this.fetchStylingData();
    this.isMobile();
  },
  mounted() {
    window.addEventListener("resize", this.isMobile);

// matomo stats
    var _paq = window._paq = window._paq || [];
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function() {
      var u="//taketheelevator.beursschouwburg.be/matomo/";
      _paq.push(['setTrackerUrl', u+'matomo.php']);
      _paq.push(['setSiteId', '1']);
      var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
      g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    })();


  },
  computed: {
    mobile() {
      return this.$store.state.mobile;
    },
    doorOpen() {
      return this.$store.state.doorOpen;
    },
    activated() {
      return this.$store.state.activated;
    },
    foldOutVolume() {
      return this.$store.state.foldOutVolume;
    }
  },
  methods: {
    setVolume(level) {
      if (this.sound == true) {
        this.$store.commit("setVolume", level);
      } else {
        this.sound = true;
        this.$store.commit("setVolume", level);

        // set timeout to prevent the first volume change to interfere with initialization
        setTimeout( function() {
          this.$store.commit("initScene", true);
        }.bind(this), 50);
        this.$store.commit("elevatorDoorOpen", true);
      }
      this.$store.commit("toggleVolumeWindow", true);
    },
    toggleVolume() {
      this.$store.commit("toggleVolumeWindow", this.foldOutVolume);
    },
    isMobile() {
      if(window.innerWidth < 850) {
        this.$store.commit("setMobile", true);
      } else {
        this.$store.commit("setMobile", false);
      }
    },
    fetchStylingData() {
      axios({
        method:'get',
        url:`cms/api/entries?id=interface/stylesheet&[options]&token=${this.token}`,
        baseURL: process.env.VUE_APP_BASE_URL,
      })
        .then(response => {
          this.stylingData = response.data.data;
          let root = document.documentElement;
          root.style.setProperty('--light-volume-color', response.data.data.accent_color);
        });
    }
  }
};
</script>

<style>
/* careful, this is not scoped! */
#instruction {
  position: relative;
  top:0;
  width:fit-content;
  margin: 0 auto;
  height:auto;
  z-index: 5000;
  background-color: var(--interface-bg-color);
  border: var(--border);
  border-radius: 0 0 12px 12px;
  padding: 8px 20px 12px 20px;
  transition: transform 1.5s;
}

#instruction p {
  color: var(--light-volume-color);
}

#instruction.instruction-hide {
    transform: translateY(-100%);
}


#app {
  position:relative;
  top:0;
  left:0;
  width:100%;
  height:100%;
  overflow: hidden;
  /* border: var(--border); */
  background: rgb(106, 113, 118);
  background: linear-gradient(
    0deg,
    rgb(106, 113, 118) 0%,
    rgba(118, 126, 129, 1) 37%,
    rgba(194, 200, 201, 1) 52%,
    rgba(118, 126, 129, 1) 68%,
    rgb(106, 113, 118) 100%
  );
}

#volume-interface {
  position:absolute;
  width:min(200px, 100%);
  padding:8px 20px 8px 20px;
  height:min(100%, 780px);
  max-height:100%;
  bottom:0px;
  right:0px;
  border-left: var(--border);
  border-top: var(--border);
  border-radius: 12px 0 0 0;
  z-index:5000;
  background-color: var(--interface-bg-color);
  transform: translateX(0%);
  transition: transform 1.5s;
}

#volume-interface.volume-transition {
  transform: translateX(100%);
}


#volume-plate {
  /* margin:15px 15px 0px 15px; */
  flex-basis: 10px;
  padding:5px;
  border: 2px solid var(--light-volume-color);
  border-radius: 10px;
}

#volume-plate p {
  padding:0px 5px 4px 5px;
  color: var(--light-volume-color);
  font-size:20px;
}

#volume-flex-content {
  position:relative;
  width:100%;
  height:100%;
  padding:8px;
  display:flex;
  flex-direction: column;
}

#sound-tab {
    position:absolute;
    left:-45px;
    bottom:40px;
    width:45px;
    height:55px;
    border-top-left-radius: 6px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 0px;
    border-width: 1px 0px 1px 0px;
    cursor: var(--pointing-cursor);
    border: var(--border);
    background-color: var(--interface-bg-color);
    
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
}

#sound-tab svg {
  position:relative;
}

.button.volume {
  position:relative;
  top:0;
  left:0;
  width:100%;
  height:100%;
  /* flex-basis:100%; */
  padding:15px;
  display:flex;
  flex-direction:column-reverse;
  flex-wrap:none;

}

.button.volume div {
  position:relative;
  width:115px;
  height:115px;
  border: var(--border);
  background-color: var(--dark-volume-color);
  margin:10px 0px 10px 0px;
  border-radius: 100px;
  transition: background-color 0.2s;
  cursor: var(--pointing-cursor) !important;
  /* outline: 4px inset rgb(169, 169, 169); */
}

/* hover colors */
.button.volume.w div:nth-child(-n+2) {
  background-color: var(--light-volume-color);
  /* border-color: var(--dark-volume-color); */
}
.button.volume.x div:nth-child(-n+3) {
  background-color: var(--light-volume-color);
}
.button.volume.y div:nth-child(-n+4) {
  background-color: var(--light-volume-color);
}
.button.volume.z div:nth-child(-n+5){
  background-color: var(--light-volume-color);
}


.button.volume div:hover {
  background-color: var(--light-volume-color);
}

/* .button.volume div:nth-child(1) {
  border-radius: 20px 0px 0px 20px;
}

.button.volume div:nth-child(5) {
  border-radius: 0px 20px 20px 0px;
} */


#elevator-doors {
  z-index:10;
  position:absolute;
  height:100%;
  width:100%;
  top:0;
  pointer-events: none;
}

#elevator-doors.open-elevator #door-1 {
  transform: translateX(100%);
}

/* #elevator-doors.open-elevator #door-2 {
  transform: translateX(100%);
} */

#door-1, #door-2 {
  display:inline-block;
  width:100%;
  height:100%;
  background: rgb(106, 113, 118);
  background: linear-gradient(
    0deg,
    rgb(106, 113, 118) 0%,
    rgba(118, 126, 129, 1) 37%,
    rgba(194, 200, 201, 1) 52%,
    rgba(118, 126, 129, 1) 68%,
    rgb(106, 113, 118) 100%
  );
  transition: transform 2s;
}

#door-1 {
  border-left: var(--border);
}

#door-2 {
  border-left: var(--border);
}



/* for smaller screens */
@media all and (max-width: 850px) {
  #tracking {
    overflow: auto;
    /* touch-action: manipulation; */
     -webkit-overflow-scrolling: auto;

    background-image: url("../public/room/5023.png");
  }

  #volume-interface {
    height:780px;
  }
}

@media all and (max-width: 380px) {
  #volume-interface {
    height:550px;
    width:140px;
    padding:8px 10px 0px 10px;
  }

  .button.volume div {
    width:73px;
    height:73px;
  }
}
</style>

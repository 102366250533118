class Navigator {

  constructor(cursorArea) {
    this.cursorArea = cursorArea;
    this.limits = [ cursorArea.clientWidth, cursorArea.clientHeight];
    this.center = [ cursorArea.clientWidth / 2, cursorArea.clientHeight / 2];
    this.lock = true;
    // init audio context
    this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    this.listener = this.audioCtx.listener;

    // init audio context
    document.body.addEventListener('click', function() {
      if (this.audioCtx.state === 'suspended') {
        this.audioCtx.resume();
      }
    }.bind(this), false);


    this.x = null;
    this.y = null;

    this.baseUrl = process.env.VUE_APP_BASE_URL + "cms/project/media/entries/";
  }

  initRoom(container, overlay, room, mobile) {
    let widthInt = parseFloat(room.width.replace(',', '.'));
    let heightInt = parseFloat(room.height.replace(',', '.'));

    // find container offset for center
    // (limits / 100) is equal to vw and vh
    // first I calculate 120 vw * room.width, which is the total size
    // then I minus a full viewwidth to see the margin that needs to be halved to make the negative offset

    let x_tranform_start = - ((100 * widthInt - 100) / 2) * (this.limits[0] / 100);
    let y_transform_start = - ((100 * heightInt - 100) / 2) * (this.limits[1] / 100);

    // init background image with according transformation or scroll starting position
    if (mobile) {
      // on mobile in elevator
      if (room.title == "elevator") {
        container.style.height = "100%";
        container.style.width = "100%";
      } else {
        // on mobile in gardens
        container.style.height = heightInt * 800 + "px";
        container.style.width = widthInt * 1000 + "px";
        this.cursorArea.scrollTo((widthInt * 500) - screen.width / 2, (heightInt * 500) - screen.height / 2);
      }
    } else {
      // on desktop
      container.style.height = heightInt * 100 + "vh";
      container.style.width = widthInt * 100 + "%";
      container.style.transform = "translate(" + x_tranform_start + "px," + y_transform_start + "px)";
      overlay.style.transform = "translate(" + x_tranform_start + "px," + y_transform_start + "px)";
    }



    // init overlay, currently not used
    overlay.style.height = heightInt * 100 + "vh";
    overlay.style.width = widthInt * 100 + "vw";


    // throttle setup for the pointermove event
    var throttleTimer;
    const throttle = (callback, time) => {
      if (throttleTimer) return;
    throttleTimer = true;
    setTimeout(() => {
        callback();
    throttleTimer = false;
    }, time);
    }

    let resizing = false;

    // add pointermove event listener for when on mobile
    if (!mobile) {
      this.cursorArea.addEventListener('pointermove', (event) => {
        throttle(() => {
          this.x = (event.clientX * 100) / this.limits[0] - 50; // Get the horizontal percentage
          this.y = (event.clientY * 100) / this.limits[1] - 50; // Get the vertical percentage
          if (this.lock == false && resizing == false) {
            navigateRoom(this.x, this.y, this.limits, room);
            // dont move audio around when the room is size 1
            if (widthInt > 1) {
              navigateSound(event.clientX, this.listener, this.center);
            }
          } else {
            this.lock = unlock(this.x);
          }
        }, 55);
      });
    } 

    // set resizing var when resizing to disable the "pointermove" event temporarily
    var afterResize;

    window.addEventListener('resize', () => {
        this.limits = [ this.cursorArea.clientWidth, this.cursorArea.clientHeight];
        this.center = [ this.cursorArea.clientWidth / 2, this.cursorArea.clientHeight / 2];
        resizing = true;
        clearTimeout(afterResize);
          afterResize = setTimeout(resizedw, 100);
    });

    function resizedw(){
      resizing = false;
    }
  }


  /*eslint-disable no-unused-vars*/
  changeVolume(newlevel, soundArray) {
    for (let [key, value] of Object.entries(soundArray)) {
      let audioEl = document.getElementById("audio-"+key);
      audioEl.volume = newlevel;
    }
  }
  /*eslint-enable no-unused-vars*/


  initSound(soundContainer, soundArray, userVolume) {
    const posX = this.center[0];
    const posY = this.center[1];
    const posZ = 0;

    if (this.listener.positionX) {
      this.listener.positionX.value = posX;
      this.listener.positionY.value = posY;
      this.listener.positionZ.value = posZ - 1000;
    } else {
      this.listener.setPosition(posX, posY, posZ - 1000);
    }

    const pannerModel = 'HRTF';
    const distanceModel = 'linear';
    const positionY = posY;
    const positionZ = posZ;
    const orientationX = 0.0;
    const orientationY = 0.0;
    const orientationZ = -1.0;

    // linear conversion of the position of the audio in relation to the window
    for (let [key, value] of Object.entries(soundArray)) {
      let normalize = function(OldValue, OldMin, OldMax, NewMin, NewMax) {
        if (OldMin != OldMax && NewMin != NewMax) {
          return (((OldValue - OldMin) * (NewMax - NewMin)) / (OldMax - OldMin)) + NewMin
        } else {
          return (NewMax + NewMin) / 2
        }
      }
      let positionX = normalize(value.location, -5, 5, 0, (this.cursorArea.clientWidth));
      var panNode = new PannerNode(this.audioCtx, {
        panningModel: pannerModel,
        distanceModel: distanceModel,
        positionX: positionX,
        positionY: positionY,
        positionZ: positionZ,
        orientationX: orientationX,
        orientationY: orientationY,
        orientationZ: orientationZ,
        rolloffFactor: 0.8,
      })
      var AudioEl = document.createElement("AUDIO");
      AudioEl.crossOrigin = 'anonymous';
      AudioEl.src = this.baseUrl + value.id + "/" + value.url;
      // window.console.log(this.baseUrl + value.id + "/" + value.url);
      AudioEl.id = "audio-"+key;
      AudioEl.loop = true;
      AudioEl.volume = userVolume;
      soundContainer.appendChild(AudioEl);
      var source = this.audioCtx.createMediaElementSource(AudioEl);
      source.connect(panNode);
      panNode.connect(this.audioCtx.destination);
    }
  }

  fadeInAudio(soundArray, userVol) {
    for (let [key, value] of Object.entries(soundArray)) {
      let audioEl = document.getElementById("audio-"+key);
      let i = 0;
      audioEl.volume = 0;
      audioEl.play();

      let convertedVol = null;

      switch(value.fullVol) {
        case "low":
          convertedVol = 0.3;
          break;
        case "mid":
          convertedVol = 0.5;
          break;
        case "loud":
          convertedVol = 0.8;
          break;
        default:
          convertedVol = 0.5;
      }

      let fadeIn = setInterval(function() {
        if (i <= 10) {
          // slowly rise the volume from 0 to (sample volume * userVolume)
          audioEl.volume = ((i / 10) * convertedVol * userVol);
          i++;
        } else {
          clearInterval(fadeIn)
        }
      },100)
    }
  }
}

function unlock(x) {
  if (x < -10 || x > 10) { // Unlocking the cursor when hover over the center
    return true;
  } else {
    return false;
  }
}

function navigateRoom(x, y, limits) {

  // navigate background image
  let container = document.getElementById("container");
  let overlay = document.getElementById("overlay");

  let normalize = function(OldValue, OldMin, OldMax, NewMin, NewMax) {
    if (OldMin != OldMax && NewMin != NewMax) {
      return (((OldValue - OldMin) * (NewMax - NewMin)) / (OldMax - OldMin)) + NewMin
    } else {
      return (NewMax + NewMin) / 2
    }
  }

  let container_moveX = normalize(x, -50, 50, 0, container.clientWidth - limits[0]);
  let overlay_moveX = normalize(x, -50, 50, 0, container.clientWidth - limits[0]);

  let container_moveY = normalize(y, -50, 50, 0, container.clientHeight - limits[1]);
  let overlay_moveY = normalize(y, -50, 50, 0, container.clientHeight - limits[1]);

  // we need client width here as well
  container.style.transform = "translate(" + (- container_moveX) + "px," + (- container_moveY) + "px)";
  overlay.style.transform = "translate(" + (- overlay_moveX) + "px," + (- overlay_moveY) + "px)";
}

function navigateSound(x, listener, center) {
  // Firefox browser compatibility issue
  if (listener.positionX) {
    listener.positionX.value = (x * 1);
  } else {
    let y = center[1];
    listener.setPosition((x * 1), y, 0)
  }

}

export default Navigator;
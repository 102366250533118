import { createApp } from "vue";
import App from './App.vue';
import router from "./router";
import store from './store'
// import axios from "axios";
// import VueAxios from "vue-axios";
import "../assets/css/global.css";
import recorder from "../js/sound-recorder"
import navigator from "../js/navigator"

const app = createApp(App)

app.config.globalProperties.recorder = recorder;
app.config.globalProperties.navigator = navigator;

app
// .use(VueAxios, axios)
.use(router)
.use(store)

app.mount("#app");

import { createStore } from 'vuex'

const store = createStore({
  state: {
      volume: 0,
      activated: false,
      mobile: null,
      doorOpen: false,
      foldOutVolume: false
  },
  mutations: {
    setVolume(state, value) {
      state.volume = value;
    },
    initScene(state, value) {
      state.activated = value;
    },
    setMobile(state, value) {
      state.mobile = value;
    },
    elevatorDoorOpen(state, value) {
      state.doorOpen = value;
    },
    toggleVolumeWindow(state, value) {
      if (value == true) {
        state.foldOutVolume = false;
      } else { 
        state.foldOutVolume = true; }
    }
  },
});

export default store;
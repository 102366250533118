class recorder {

  constructor(stop, canvas, mainSection) {
    // disable stop button while not recording
    stop.disabled = true;
    window.onresize = function () {
      canvas.width = mainSection.offsetWidth;
    }
    window.onresize();
  }

  setupRecorder(record, stop, soundClips, canvas) {
    //main block for doing the audio recording
    if (navigator.mediaDevices.getUserMedia) {
      console.log('getUserMedia supported.');

      record.addEventListener('click', function() { startRecording(record, stop, canvas, soundClips) });
      
    } else {
      console.log('getUserMedia not supported on your browser!');
    }
  }
}

function makeMP3() {
  // server side mp3 transcoding from blob and saving it in API
}


function startRecording(record, stop, canvas, soundClips) {
  // visualiser setup - create web audio api context and canvas
  let audioCtx;
  const canvasCtx = canvas.getContext("2d");

  const constraints = { audio: true };
  let chunks = [];

  let onSuccess = function (stream) {
    const mediaRecorder = new MediaRecorder(stream);

    startVisualization(stream, canvas, audioCtx, canvasCtx);

    mediaRecorder.ondataavailable = function (e) {
      chunks.push(e.data);
    }

    stop.addEventListener('click', function() { 
      stopRecording(record, stop, mediaRecorder, soundClips, chunks) 
      stopVisualization(canvas, canvasCtx)
    });

    mediaRecorder.start();
    console.log(mediaRecorder.state);
    console.log("recorder started");
    record.style.background = "red";

    stop.disabled = false;
    record.disabled = true;
  };
  navigator.mediaDevices.getUserMedia(constraints).then(onSuccess, onError);
  let onError = function (err) {
    console.log('The following error occured: ' + err);
  }
}


function stopRecording(record, stop, mediaRecorder, soundClips, chunks) {
  mediaRecorder.stop();
  console.log(mediaRecorder.state);
  console.log("recorder stopped");
  record.style.background = "";
  record.style.color = "";
  // mediaRecorder.requestData();

  stop.disabled = true;
  record.disabled = false;

  mediaRecorder.onstop = function (e) {
    console.log("data available after MediaRecorder.stop() called." + e);

    const clipName = prompt('Enter a name for your sound clip?', 'My unnamed clip');

    const clipContainer = document.createElement('article');
    const clipLabel = document.createElement('p');
    const audio = document.createElement('audio');
    const deleteButton = document.createElement('button');
    const sendButton = document.createElement('button');

    clipContainer.classList.add('clip');
    audio.setAttribute('controls', '');
    deleteButton.textContent = 'Delete';
    sendButton.textContent = 'Send';
    sendButton.className = 'delete';
    deleteButton.className = 'delete';
    clipLabel.className = "cliplabel";

    if (clipName === null) {
      clipLabel.textContent = 'My unnamed clip';
    } else {
      clipLabel.textContent = clipName;
    }

    clipContainer.appendChild(audio);
    clipContainer.appendChild(clipLabel);
    clipContainer.appendChild(deleteButton);
    clipContainer.appendChild(sendButton);
    soundClips.appendChild(clipContainer);

    audio.controls = true;
    const blob = new Blob(chunks, { 'type': 'audio/ogg; codecs=opus' });
    chunks = [];

    const audioURL = window.URL.createObjectURL(blob);
    audio.src = audioURL;
    console.log("recorder stopped");

    deleteButton.onclick = function (e) {
      let evtTgt = e.target;
      evtTgt.parentNode.parentNode.removeChild(evtTgt.parentNode);
    }

    sendButton.onclick = function () {
      makeMP3();
    }

    clipLabel.onclick = function () {
      const existingName = clipLabel.textContent;
      const newClipName = prompt('Enter a new name for your sound clip?');
      if (newClipName === null) {
        clipLabel.textContent = existingName;
      } else {
        clipLabel.textContent = newClipName;
      }
    }
    window.console.log(mediaRecorder.stream);
    mediaRecorder.stream.getTracks() //get all tracks from the stream
      .forEach(track /*of type MediaStreamTrack*/ => track.stop()); 
  }
}

// audio needs separate init and start stop functions as well

function startVisualization(stream, canvas, audioCtx, canvasCtx) {

  if (!audioCtx) {
    audioCtx = new AudioContext();
  }

  const source = audioCtx.createMediaStreamSource(stream);

  const analyser = audioCtx.createAnalyser();
  analyser.fftSize = 2048;
  const bufferLength = analyser.frequencyBinCount;
  const dataArray = new Uint8Array(bufferLength);

  source.connect(analyser);
  //analyser.connect(audioCtx.destination);
  canvas.style.visibility = "visible";

  draw()

  function draw() {
    const WIDTH = canvas.width
    const HEIGHT = canvas.height;

    requestAnimationFrame(draw);

    analyser.getByteTimeDomainData(dataArray);

    canvasCtx.fillStyle = 'rgb(200, 200, 200)';
    canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

    canvasCtx.lineWidth = 2;
    canvasCtx.strokeStyle = 'rgb(0, 0, 0)';

    canvasCtx.beginPath();

    let sliceWidth = WIDTH * 1.0 / bufferLength;
    let x = 0;


    for (let i = 0; i < bufferLength; i++) {

      let v = dataArray[i] / 128.0;
      let y = v * HEIGHT / 2;

      if (i === 0) {
        canvasCtx.moveTo(x, y);
      } else {
        canvasCtx.lineTo(x, y);
      }

      x += sliceWidth;
    }
    canvasCtx.lineTo(canvas.width, canvas.height / 2);
    canvasCtx.stroke();
  }
}

function stopVisualization(canvas, canvasCtx) {
  canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
  canvas.style.visibility = "hidden";
}

export default recorder;

import { createRouter, createWebHistory } from "vue-router";

import NotFound from "../components/NotFound.vue";

const Entrance = () => import('../components/Entrance');
const Zone1 = () => import('../components/Zone1');
// import GLSLinVue from "../components/GLSLinVue.vue"
// import VoiceRecorder from "../components/VoiceRecorder.vue";



const routes = [
  {
    path: "/",
    name: "TAKE THE ELEVATOR ...",
    component: Entrance,
  },
  {
    path: "/out-loud",
    name: "OUT LOUD",
    component: Zone1,
  },
  {
    path: '/:pathMatch(.*)*', 
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory('/'),
  base: process.env.VUE_APP_BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  document.title = to.name;
  next();
});

export default router;
